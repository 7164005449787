import type { Pool } from './Pool';

/**
 * Doctor pools used by insurance companies.
 */
export const allPools: readonly Pool[] = [
  {
    name: 'DNB',
    apiKeyPrefix: 'cf',
  },
  {
    name: 'Eika',
    apiKeyPrefix: '65',
  },
  {
    name: 'Protector-FREE',
    apiKeyPrefix: 'b1',
  },
  {
    name: 'Protector-150NOK',
    apiKeyPrefix: 'a1',
  },
  {
    name: 'Landkreditt',
    apiKeyPrefix: '57',
  },
];
